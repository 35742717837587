// Step 1: Import React
import * as React from 'react'
import Layout from '../components/layout'
import { container } from '../components/layout.module.css'
import { Link } from 'gatsby';
import { StaticImage } from "gatsby-plugin-image"

// Step 2: Define your component
const Learn4 = () => {
  return (
    <div className={container}>
      <main>
        <Layout>

          <StaticImage src="../../static/4-learn.jpg" alt="Get Into Investing!" layout="constrained"/>

          <table align="center" width="90%">
            <tr>
              <td>
                <div><br />
                  <p>
                  The last and probably most important lesson that <i>Get Into Investing!</i> teaches is financial security. The younger you start, the better your possibilities of financial independence from your investing. While <i>Get Into Investing!</i> isn't a get rich scheme, it does teach a very powerful technique for newer and seasoned investors alike. 
                  </p>
                  <p>
                  Using the rules enforced throughout <i>Get Into Investing!</i> will teach the reader to be more confident with investing and be sure that they can check the status on it any time. The text provides reinforcement with homework to guide you into learning more about the subject of investing. 
                  </p>
                  <p>
                  Over time, using the strategies discussed through <i>Get Into Investing!</i>, readers will develop a sense to analyze opportunities them using strict, easy to follow rules and firm knowledge of their financial position. Did I mention there was very little math involved? 
                  </p>
                  <p>
                  What are you waiting for? It's time to <i>Get Into Investing!</i>
                  </p>
                </div>
                <div align="center">
                    <p>
                      <>  <Link className="btn btn-outline-secondary" to="/buy"> Buy Literature </Link> </>
                      <>  <Link className="btn btn-outline-secondary" to="/social"> Social Media </Link> </>
                    </p>
                  </div>
              </td>  
            </tr>
          </table>

        </Layout>
      </main>
    </div>
  )
}

// Step 3: Export your component
export default Learn4